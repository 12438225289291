import React from "react";
import WhyCloudLabFlexibilityCloud from "components/WhyCloudLabsTables/flexibility/flexibility-cloud";
import WhyCloudLabFlexibilityTraditional from "components/WhyCloudLabsTables/flexibility/flexibility-traditional";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsInPageNav,
  WhyCloudLabsNav,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabFlexibilityPage = ({ data, location }) => {
  const title = "On-Demand Access to Scientific Instrumentation";
  const description = "Get new lab capabilities online in days, not months";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsInPageNav
          data={data.inPageNav.traditional}
          kind={"traditional"}
          tab={location && location.hash}
        >
          <WhyCloudLabFlexibilityTraditional data={data.inTable.traditional} />
        </WhyCloudLabsInPageNav>
        <WhyCloudLabsInPageNav
          data={data.inPageNav.cloud}
          kind={"cloud"}
          tab={location && location.hash}
        >
          <WhyCloudLabFlexibilityCloud data={data.inTable.cloud} />
        </WhyCloudLabsInPageNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabFlexibilityPage;

export const query = graphql`
  query WhyCloudLabFlexibilityPage {
    inPageNav: whyCloudLabsJson(section: { eq: "flexibility" }) {
      traditional {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
      cloud {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
    }
    inTable: whyCloudLabsJson(section: { eq: "flexibility" }) {
      traditional {
        content {
          text
          title
          image
          alt
          caption
          list {
            text
          }
        }
      }
      cloud {
        content {
          header
          text
          image
        }
      }
    }
  }
`;
