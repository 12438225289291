import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList } from "../components";
import { device } from "utils";

const WhyCloudLabFlexibilityTraditional = ({ data }) => {
  const {
    content: [row1Content, row2Content, row3Content, row4Content],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Row1Node>
          <S.Row1Text>{row1Content[0].text}</S.Row1Text>
        </S.Row1Node>
      </S.Row1>
      <S.Row2>
        <S.ImageSmallNode>
          <S.ImageTitle className="pie-chart">
            {row2Content[0].title}
            <sup>1</sup>
          </S.ImageTitle>
          <S.Image
            className="pie-chart"
            src={row2Content[0].image}
            loading="lazy"
            alt={row2Content[0].alt}
            title={row2Content[0].caption}
          />
        </S.ImageSmallNode>
        <S.Row2TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row2Content,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2TextNode>
      </S.Row2>
      <S.Row3>
        <S.ImageLargeNode>
          <S.ImageTitle className="bar-graphs">
            {row3Content[0].title}
            <sup>1</sup>
          </S.ImageTitle>
          <S.Image
            src={row3Content[0].image}
            loading="lazy"
            alt={row3Content[0].alt}
            title={row3Content[0].title}
          />
        </S.ImageLargeNode>
        <S.Row3TextNode>
          <S.Row3Text>{row3Content[1].text}</S.Row3Text>
        </S.Row3TextNode>
      </S.Row3>
      <S.Row4>
        <S.Row4TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row4Content,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row4TextNode>
        <S.ImageLargeNode>
          <S.ImageTitle className="bar-graphs">
            {row4Content[0].title}
            <sup>1</sup>
          </S.ImageTitle>
          <S.Image
            className="bar-graph-2"
            src={row4Content[0].image}
            loading="lazy"
            alt={row4Content[0].alt}
            title={row4Content[0].title}
          />
          <S.ImageCaption>
            <sup>1</sup>
            {row4Content[0].caption}
          </S.ImageCaption>
        </S.ImageLargeNode>
        <S.FooterImage
          src={row4Content[1].image}
          loading="lazy"
          alt="traditional-scientist"
          title="Traditional Scientist"
        />
      </S.Row4>
    </S.Table>
  );
};

WhyCloudLabFlexibilityTraditional.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabFlexibilityTraditional.defaultProps = {};

export default WhyCloudLabFlexibilityTraditional;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableNodes = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  padding: 2rem;
`;
S.Row1 = styled.div`
  display: flex;
`;
S.Row2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row4 = styled.div`
  display: flex;
  flex: 0 0 105rem;
  flex-direction: column;
  margin-top: 0.5rem;
  position: relative;

  @media ${device.md} {
    flex: 0 0 71rem;
    flex-direction: row;
  }
`;
S.Row1Node = styled(S.TableNodes)`
  color: #b1bac2;
  display: flex;
  flex: 1;
  font-weight: 300;
  line-height: 2.2rem;
`;
S.Row2TextNode = styled(S.TableNodes)`
  background: #000000;
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem 5rem 1rem;

  @media ${device.md} {
    flex: 2;
  }
`;
S.Row3TextNode = styled(S.TableNodes)`
  background: #000000;
  display: flex;
  align-items: center;
  padding: 3rem 5rem;

  @media ${device.md} {
    flex: 1;
  }
`;
S.Row4TextNode = styled(S.TableNodes)`
  background: #000000;
  display: flex;
  padding: 5rem 3rem 5rem 1rem;

  @media ${device.md} {
    flex: 1;
  }
`;
S.ImageSmallNode = styled(S.TableNodes)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 3rem;
`;
S.ImageLargeNode = styled(S.TableNodes)`
  flex: 2;
`;
S.Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  transform: scale(0.9);
  &.pie-chart {
    transform: scale(0.8);
  }
  &.bar-graph-2 {
    transform: scale(1.1);
  }

  @media ${device.md} {
    margin: 1rem auto;
    transform: scale(1);
    &.pie-chart {
      transform: scale(1);
    }
  }
`;
S.ImageTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7rem;
  text-align: center;
  padding-bottom: 1rem;
  &.pie-chart {
    color: #c89f58;
  }
  &.bar-graphs {
    padding-bottom: 0;
  }
  sup {
    padding-left: 0.25rem;
  }

  @media ${device.md} {
    text-align: left;
    &.bar-graphs {
      padding-left: 5.5rem;
    }
  }
`;
S.ImageCaption = styled.div`
  color: #7b8690;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-style: italic;
  text-align: right;
  padding: 4rem 6rem;
  sup {
    padding-right: 0.25rem;
  }
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  max-width: 100%;
`;
S.BulletsNode = styled.div`
  flex: 1;
`;
S.Row1Text = styled.div`
  flex: 2;
`;
S.Row3Text = styled.div`
  flex: 1;
`;
