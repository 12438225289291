import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList } from "../components";
import { Link } from "gatsby";
import { device, routes } from "utils";

const WhyCloudLabFlexibilityCloud = ({ data }) => {
  const {
    content: [
      row1Content,
      row2Content,
      row3Content,
      row4Content,
      row5Content,
      row6Content,
    ],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Row1Header>{row1Content[0].header}</S.Row1Header>
        <S.BulletsNode className="row1">
          <S.BulletList>
            <S.Bullet>{row1Content[1].text}</S.Bullet>
            <S.Bullet>
              {"Complete list in "}
              <S.Link to={routes("instrumentation")}>
                Scientific Instrumentation
              </S.Link>
            </S.Bullet>
          </S.BulletList>
        </S.BulletsNode>
      </S.Row1>
      <S.Row2>
        <S.BulletsNode>
          {BulletList({
            data: row2Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row2Header>{row2Content[0].header}</S.Row2Header>
      </S.Row2>
      <S.Row3>
        <S.Row3Header>{row3Content[0].header}</S.Row3Header>
        <S.BulletsNode>
          {BulletList({
            data: row3Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row3>
      <S.Row4>
        <S.BulletsNode>
          {BulletList({
            data: row4Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row4Header>{row4Content[0].header}</S.Row4Header>
      </S.Row4>
      <S.Row5>
        <S.Row5Header>{row5Content[0].header}</S.Row5Header>
        <S.BulletsNode className="row5">
          {BulletList({
            data: row5Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row5>
      <S.Row6>
        <S.BulletsNode>
          <S.BulletList>
            <S.Bullet>
              {"~5 new unique "}
              <S.Link to={routes("instrumentation")}>instrument</S.Link>
              {" models are onboarded into the cloud every month"}
            </S.Bullet>
            <S.Bullet>{row6Content[2].text}</S.Bullet>
          </S.BulletList>
        </S.BulletsNode>
        <S.Row6Header>{row6Content[0].header}</S.Row6Header>
        <S.FooterImage
          src={row6Content[0].image}
          loading="lazy"
          alt="cloud-scientist"
          title="Cloud Scientist"
        />
      </S.Row6>
    </S.Table>
  );
};

WhyCloudLabFlexibilityCloud.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabFlexibilityCloud.defaultProps = {};

export default WhyCloudLabFlexibilityCloud;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableHeaders = styled.div`
  color: ${({ theme }) => theme.greenLink};
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
  padding: 3rem;
`;
S.Row1 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
  }
`;
S.Row2 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column-reverse;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
  }
`;
S.Row4 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column-reverse;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
  }
`;
S.Row5 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
  }
`;
S.Row6 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex: 0 0 55rem;
  margin-top: 0.5rem;
  flex-direction: column-reverse;
  position: relative;

  @media ${device.md} {
    flex: 0 0 40.5rem;
    flex-direction: row;
  }
`;
S.Row1Header = styled(S.TableHeaders)`
  @media ${device.md} {
    flex: 1;
  }
`;
S.Row2Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding-left: 5rem;
    flex: 1.5;
  }
`;
S.Row3Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 2rem 3rem;
    flex: 1;
  }
`;
S.Row4Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding-left: 5rem;
    flex: 1.5;
  }
`;
S.Row5Header = styled(S.TableHeaders)`
  @media ${device.md} {
    flex: 1;
  }
`;
S.Row6Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding-left: 5rem;
    flex: 1.5;
  }
`;
S.BulletsNode = styled.div`
  padding: 2rem;
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  background: #000000;
  flex: 1;
  &.row1 {
    padding-top: 3rem;
  }
  &.row5 {
    padding-top: 4rem;
  }
`;
S.BulletList = styled.ul``;
S.Bullet = styled.li`
  padding-left: 1rem;
  padding-bottom: 1rem;
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  right: 1rem;
  max-width: 100%;
`;
S.Link = styled(Link)`
  color: #b1bac2;
  text-decoration: underline;
  font-size: 1.4rem;
  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;
